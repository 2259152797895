
.selected-items {
  display: flex;
  flex-wrap: wrap;
}
[dir] .selected-items {
  margin-top: 10px;
}
.chip {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
}
[dir] .chip {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
[dir=ltr] .chip {
  margin-right: 5px;
}
[dir=rtl] .chip {
  margin-left: 5px;
}
.chip-close {
  font-size: 14px;
}
[dir] .chip-close {
  background: none;
  border: none;
  cursor: pointer;
}
[dir=ltr] .chip-close {
  margin-left: 10px;
}
[dir=rtl] .chip-close {
  margin-right: 10px;
}
.chip-close:hover {
  color: red;
}
